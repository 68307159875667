import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import image4 from "../Images/image4.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";

export default function Ecosystem() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box py={matches ? 5 : 10} position="relative" zIndex={1}>
      {/* <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: matches
            ? ""
            : "0px 250px 230px 230px rgb(255,148,255,0.3)",
          borderRadius: "50%",
          position: "absolute",
          top: "30%",
          right: "10%",
        }}
      /> */}
      <Container maxWidth="xl">
        <Grid
          container
          spacing={7}
          position="relative"
          zIndex={1}
          px={!matches && 12}
          display="flex"
          alignItems="center"
        >
          <Grid
            item
            md={6}
            xs={12}
            // justifyContent="center"
            // alignItems="center"
            // display="flex"
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "25px" : "36px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                Rewards and Tier
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {" "}
                  System
                </span>
              </Typography>
              {matches && (
                <img
                  src={image4}
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                  width="100%"
                  alt=""
                />
              )}
              <Typography
                mt={2}
                mb={4}
                sx={{
                  fontWeight: 400,
                  fontFamily: "'Poppins'",
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                Experience unprecedented rewards with Retik's DeFi debit cards –
                an embodiment of financial freedom. Within our tier system –
                Silver, Gold, and Diamond – each transaction fuels your journey
                with increasing cashback benefits. Silver cardholders receive
                2.5% cashback, while Gold offers 3.75% and Diamond leads with
                5%.
              </Typography>

              {/* <StyledButton width="160px">Buy $RETIK</StyledButton> */}
            </Box>
          </Grid>
          {!matches && (
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <img src={image4} width="80%" alt="" />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
