import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import image1 from "../Images/image1.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";
import CircleIcon from "@mui/icons-material/Circle";

export default function Gambling() {
  const matches = useMediaQuery("(max-width:950px)");
  return (
    <Box
      mt={matches ? 3 : 12}
      py={matches ? 5 : 10}
      sx={{
        background: "#1C1D23",
      }}
      position="relative"
      zIndex={1}
    >
      <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: matches ? "" : "0px 400px 300px 300px rgb(70,56,95)",
          borderRadius: "50%",
          position: "absolute",
          top: "-20%",
          left: "10%",
        }}
      />
      <Container maxWidth="xl">
        <Grid
          container
          spacing={7}
          position="relative"
          zIndex={1}
          display="flex"
          alignItems="center"
          px={!matches && 5}
        >
          {!matches && (
            <Grid item xs={12} md={6}>
              <img src={image1} width="96%" alt="" />
            </Grid>
          )}

          <Grid
            item
            md={6}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "25px" : "36px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Retik
                </span>{" "}
                DeFi Crypto Debit Cards
              </Typography>
              {matches && (
                <img
                  src={image1}
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                  width="100%"
                  alt=""
                />
              )}
              <Typography
                mt={3}
                mb={5}
                sx={{
                  fontWeight: 400,
                  fontFamily: "'Poppins'",
                  fontSize: matches ? "16px" : "1.125rem",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                Retik DeFi Debit Cards redefine financial accessibility and
                security. Offering anonymous transactions without the need for
                KYC, prioritizing user privacy.
                <br /> Retik DeFi Debit Cards offer a range of benefits,
                including:
              </Typography>
              <Box display="flex">
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    Privacy:
                  </span>{" "}
                  Anonymous transactions without KYC.
                </Typography>
              </Box>
              <Box display="flex">
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    Daily Convenience:
                  </span>{" "}
                  Perfect for daily transactions, online and offline, from
                  shopping to dining. Global Accessibility: Use them across
                  borders, whether you're at a local store or traveling abroad.
                </Typography>
              </Box>
              <Box display="flex">
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    Transaction Efficiency:
                  </span>{" "}
                  Swift and secure transactions, enhancing the overall payment
                  experience
                </Typography>
              </Box>
              <Box display="flex">
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    ATM Accessibility:
                  </span>{" "}
                  Withdraw cash anywhere in the world.
                </Typography>
              </Box>
              <Box display="flex">
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    Security:
                  </span>{" "}
                  Enjoy anonymous transactions while keeping your funds secure
                  in Retik's non custodial DeFi wallet.
                </Typography>
              </Box>
              <Box display="flex">
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    Rewards Program:
                  </span>{" "}
                  Earn rewards with every use, turning spending into savings.
                </Typography>
              </Box>
              <Box display="flex" mb={2}>
                <CircleIcon
                  style={{ color: "#fff", fontSize: "12px", marginTop: "7px" }}
                />
                <Typography
                  ml={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: matches ? "14px" : "16px",
                    color: "#FFFFFF",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                  >
                    Airport Lounge Access:
                  </span>{" "}
                  Enjoy complimentary airport lounge access worldwide.
                </Typography>
              </Box>
              {/* <StyledButton width="160px">Buy $RETIK</StyledButton> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
