import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  Hidden,
  InputBase,
  Menu,
  MenuItem,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import {
  StyledButton,
  StyledText,
  StyledTextBold,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import Loading from "../components/SmallComponents/loading";
import { AppContext } from "../utils";
import { logo } from "../components/SmallComponents/Images";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import eth from "../Images/eth.svg";
import usdc from "../Images/usdc.svg";
import usdt from "../Images/usdt.svg";
import eth1 from "../Images/eth1.png";
import usdc1 from "../Images/usdc1.png";
import usdt1 from "../Images/usdt1.png";
import { BsTwitter } from "react-icons/bs";
import { FaRedditAlien } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { BsBellFill } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import CloseIcon from "@mui/icons-material/Close";
import { formatUnits, parseUnits } from "viem";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  usdcReadFunction,
  usdcWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { fetchBalance } from "@wagmi/core";
import { preSaleAddress } from "../ConnectivityAssets/environment";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { BsRocketTakeoff } from "react-icons/bs";
import linktree from "../Images/linktree.png";
import tweet from "../Images/tweet.png";
import tele from "../Images/tele.png";
import youtube from "../Images/youtube.png";
import med from "../Images/med.png";

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function HeroSection() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width: 1420px)");

  const Paragraph = styled(Typography)({
    fontWeight: 400,
    fontFamily: "'Poppins'",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
  });

  const MianHeading = styled(Typography)({
    fontFamily: "'Poppins'",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: matches ? "30px" : "60px",
    lineHeight: "100.2%",
    color: "#FFFFFF",
  });

  const TextInput = styled(TextField)({
    "& .MuiInputBase-input": {
      height: "40px",
      fontSize: "17px",
      fontWeight: "500",
      textAlign: "left",
      color: "#000000",
      fontFamily: "Poppins",
      backgroundColor: "transparent",
      paddingLeft: "15px",
      "&::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openmenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { open } = useWeb3Modal();
  const { account } = useContext(AppContext);
  const [loading, setloading] = useState();
  const [token, setToken] = useState("ETH");
  const [enteredAmount, setEnteredAmount] = useState("");
  const [enterToken, setEnterToken] = useState("");
  const [soldToken, setSoldToken] = useState(0);
  const [supply, setSupply] = useState(0);
  const [totalRaised, setTotalRaised] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showtokenToeth, setShowtokenToeth] = useState(0);
  const [showtokenTousdt, setShowtokenTousdt] = useState(0);
  const [claimAmount, setClaimAmount] = useState("");
  const [currentPresaleID, setCurrentPresaleID] = useState("");
  const [tokenPrice, setTokenPrice] = useState(0);
  const [nextStagePrice, setNextStagePrice] = useState(0);
  const [holidings, setHoldings] = useState(0);
  const [tokenSoldHardcap, settokenSoldHardcap] = useState(0);
  const [usdtSoldHardcap, setusdtSoldHardcap] = useState(0);
  const [stagePerholidings, setStagePerholidings] = useState([]);
  const [openCliambox, setOpenCliambox] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [callFunction, setCallFunction] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isButtonClicked, setButtonClicked] = useState(true);
  const [isButtonClicked1, setButtonClicked1] = useState(false);
  const handlebgClick = () => {
    // Toggle the state when the button is clicked
    setButtonClicked(!isButtonClicked);
    setButtonClicked1(false);
  };
  const handlebgClick1 = () => {
    // Toggle the state when the button is clicked

    setButtonClicked1(!isButtonClicked1);
    setButtonClicked(false);
  };
  const handleOpenBoxClose = () => {
    setOpenBox(false);
  };
  const handleOpenBox = () => {
    setOpenBox(true);
  };
  const handleCloseClaim = () => {
    setOpenCliambox(false);
  };
  // const handleOpenClaim = () => {
  //   setOpenCliambox(true);
  // };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  useEffect(() => {
    (async () => {
      try {
        let currentID = await preSaleReadFunction("currentSale");
        setCurrentPresaleID(currentID.toString());
        let presaleData = await preSaleReadFunction("presale", [currentID]);
        let priceIndollar = +formatUnits(presaleData[2].toString(), 18);

        let actualprice = 1 / priceIndollar;
        setTokenPrice(actualprice);
        let nextStagePrice = +formatUnits(presaleData[3].toString(), 18);
        let nextActualprice = 1 / nextStagePrice;
        setNextStagePrice(nextActualprice);

        let totalSoldTokens = 0,
          totalRaisedAmount = 0,
          totalTokenSOldhardcap = 0,
          totalUSDThardcap = 0;

        for (let i = 1; i <= Number(currentID.toString()); i++) {
          const [
            startTime,
            endTime,
            price,
            nextStagePrice,
            Sold,
            tokensToSell,
            UsdtHardcap,
            amountRaised,
            Active,
            isEnableClaim,
          ] = await preSaleReadFunction("presale", [i]);

          totalSoldTokens =
            totalSoldTokens + Number(formatUnits(Sold.toString(), 18));

          totalRaisedAmount =
            totalRaisedAmount + Number(formatUnits(amountRaised.toString(), 6));

          totalTokenSOldhardcap =
            totalTokenSOldhardcap +
            Number(formatUnits(tokensToSell.toString(), 18));

          totalUSDThardcap =
            totalUSDThardcap + Number(formatUnits(UsdtHardcap.toString(), 6));
          console.log(totalUSDThardcap, "totalUSDThardcap");
        }

        setSoldToken(totalSoldTokens);
        setTotalRaised(totalRaisedAmount);
        setusdtSoldHardcap(totalUSDThardcap);
        settokenSoldHardcap(totalTokenSOldhardcap);

        let progressBar = (+totalSoldTokens / +totalTokenSOldhardcap) * 100;
        setProgress(progressBar);
        console.log(progressBar, "progressBar");
        //////////////////////////////max balances/////////////////////////

        if (token === "USDT") {
          let usdtBalance = await usdtReadFunction("balanceOf", [account]);
          console.log(+formatUnits(usdtBalance.toString(), 6), "usdtBalance");
          setBalance(+formatUnits(usdtBalance.toString(), 6));
        } else if (token === "USDC") {
          let usdcBalance = await usdcReadFunction("balanceOf", [account]);
          console.log(+formatUnits(usdcBalance.toString(), 6), "usdcBalance");
          setBalance(+formatUnits(usdcBalance.toString(), 6));
        } else {
          const { formatted } = await fetchBalance({
            address: account,
          });
          let ethBalance = +formatted - 0.0075;
          console.log(ethBalance, "ethBalance");
          setBalance(ethBalance);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    setCallFunction(false);
  }, [account, supply, callFunction, soldToken, token]);
  useEffect(() => {
    (async () => {
      try {
        if (account) {
          let currentID = await preSaleReadFunction("currentSale");
          let claimableAmount = 0;
          let arr = [];
          for (let i = 1; i <= +currentID.toString(); i++) {
            let userClaimData = await preSaleReadFunction("userClaimData", [
              account,
              i,
            ]);
            claimableAmount =
              claimableAmount + +formatUnits(userClaimData[2].toString(), 18);
            let obj = {
              holdingAmount: +formatUnits(userClaimData[2].toString(), 18),
              investedAmount: +formatUnits(userClaimData[0].toString(), 6),
              stage: i.toString(),
            };
            arr.push(obj);
          }
          console.log([...arr], "array");
          console.log(claimableAmount, "claimableAmount");
          setHoldings(claimableAmount);
          setStagePerholidings([...arr]);
        }
      } catch (error) {
        console.log(error);
      }
    })();
    setCallFunction(false);
  }, [account, callFunction]);

  useEffect(() => {
    async function fetchData() {
      try {
        let currentID = await preSaleReadFunction("currentSale");
        let usdtToTokens = await preSaleReadFunction("usdtToTokens", [
          currentID.toString(),
          parseUnits(enteredAmount.toString(), 6),
        ]);
        setShowtokenTousdt(+formatUnits(usdtToTokens.toString(), 18));
        console.log(+formatUnits(usdtToTokens.toString(), 18));

        let ethToTokens = await preSaleReadFunction("ethToTokens", [
          currentID.toString(),
          parseUnits(enteredAmount.toString(), 18),
        ]);
        setShowtokenToeth(+formatUnits(ethToTokens.toString(), 18));
        console.log(
          +formatUnits(ethToTokens.toString(), 18),
          "setShowtokenToeth"
        );
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [enteredAmount]);

  useEffect(() => {
    (async () => {
      try {
        if (enteredAmount > 0) {
          if (token === "USDT" || token === "USDC") {
            let currentID = await preSaleReadFunction("currentSale");
            let usdtToTokens = await preSaleReadFunction("usdtToTokens", [
              currentID.toString(),
              parseUnits(enteredAmount.toString(), 6),
            ]);
            console.log(usdtToTokens, "usdtToTokens");
            setEnterToken(+formatUnits(usdtToTokens.toString(), 18));
          } else {
            let currentID = await preSaleReadFunction("currentSale");
            let ethToTokens = await preSaleReadFunction("ethToTokens", [
              currentID.toString(),
              parseUnits(enteredAmount.toString(), 18),
            ]);
            console.log(ethToTokens, "ethToTokens");
            setEnterToken(+formatUnits(ethToTokens.toString(), 18));
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [enteredAmount, token]);
  const onChangeHandler = async (e, status) => {
    const { name, value } = e?.target;
    try {
      if (name === "usdt") {
        setEnteredAmount(value);
      } else if (name === "retik") {
        setEnterToken(value);
        if (value > 0) {
          if (token === "USDT" || token === "USDC") {
            let tokenToUSDT = +value / 100;
            setEnteredAmount(+tokenToUSDT);
          } else {
            let tokenToeth = +value / 176079.4916;
            setEnteredAmount(+tokenToeth.toString());
            // console.log(+tokenToeth, "tokenToeth");
          }
        }
      }
      if (value === "") {
        setEnteredAmount("");
        setEnterToken("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buyHandler = async () => {
    try {
      if (!account) {
        return showAlert("Please connect your wallet");
      }
      if (!enteredAmount) {
        return showAlert("Please enter amount");
      }
      console.log(enteredAmount, token, "check");
      setloading(true);
      if (token === "USDT") {
        let allowance = await usdtReadFunction("allowance", [
          account,
          preSaleAddress,
        ]);
        let checkAllowance = +formatUnits(allowance.toString(), 6).toString();
        if (checkAllowance > 0 && checkAllowance < enteredAmount) {
          showAlert("allowance should be greater than the amount you entered.");
        } else if (!checkAllowance) {
          await usdtWriteFunction("approve", [
            preSaleAddress,
            parseUnits(enteredAmount.toString(), 6).toString(),
          ]);
        }

        // }

        await preSaleWriteFunction("buyWithUSDT", [
          parseUnits(enteredAmount.toString(), 6).toString(),
        ]);
      }
      // else if (token === "USDC") {
      //   let allowanceusdt = await usdtReadFunction("allowance", [
      //     account,
      //     preSaleAddress,
      //   ]);
      //   if (
      //     +formatUnits(allowanceusdt.toString(), 6).toString() < enteredAmount
      //   ) {
      //     await usdtWriteFunction("approve", [
      //       preSaleAddress,
      //       "999999999999999999999999999999",
      //     ]);
      //   }
      //   let allowance = await usdcReadFunction("allowance", [
      //     account,
      //     preSaleAddress,
      //   ]);
      //   if (+formatUnits(allowance.toString(), 6).toString() < enteredAmount) {
      //     await usdcWriteFunction("approve", [
      //       preSaleAddress,
      //       "999999999999999999999999999999",
      //     ]);
      //   }
      //   await preSaleWriteFunction("buyWithUSDC", [
      //     parseUnits(enteredAmount.toString(), 6).toString(),
      //   ]);
      // }
      else {
        await preSaleWriteFunction(
          "buyWithEth",
          [],
          parseUnits(enteredAmount.toString(), 18).toString()
        );
      }
      setCallFunction(true);
      setloading(false);
      setEnteredAmount(0);
      setEnterToken(0);

      setAlertState({
        open: true,
        message: "Transaction confirmed!!!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setloading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  };
  const claimHandler = async () => {
    try {
      const presaleID = await preSaleReadFunction("presaleId");
      console.log(presaleID, "presaleID");
      if (+claimAmount > +presaleID.toString()) {
        return showAlert("This presale phase does not exist");
      }
      await preSaleWriteFunction("claimAmount", [claimAmount]);
      setAlertState({
        open: true,
        message: "Calimed successfully!!!",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setloading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  };
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              background: "#000",
              boxShadow: "black 0px 0px 8px 1px",
              borderRadius: "14px",
              p: 2,
            },
          },
        }}
        open={openCliambox}
        onClose={handleCloseClaim}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "20px",
              color: "#fff",
            }}
          >
            Enter presale ID{" "}
          </Typography>
          <Box
            mt={1}
            sx={{
              bgcolor: "#F1F4F6",
              borderRadius: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              type="number"
              style={{
                height: "40px",
                width: "100%",
                fontSize: "17px",
                fontWeight: "500",
                textAlign: "left",
                color: "#000000",
                fontFamily: "Poppins",
                backgroundColor: "transparent",
                paddingLeft: "15px",
                border: "none",
                outline: "none",
              }}
              placeholder="Phase id"
              value={claimAmount}
              onChange={(e) => setClaimAmount(e.target.value)}
            />
          </Box>
          <Box display="flex" justifyContent="center" mt={2}>
            <StyledButton width="120px" onClick={claimHandler}>
              Claim
            </StyledButton>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px", // Set your width here
              background: "#000",
              boxShadow: "black 0px 0px 8px 1px",
              borderRadius: "14px",
              p: 2,
            },
          },
        }}
        open={openBox}
        onClose={handleOpenBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box>
          <Box display="flex" justifyContent="space-between">
            <Box />
            <Typography
              my={1}
              mb={4}
              sx={{
                fontSize: "22px",
                fontWeight: "700",
                fontFamily: "Poppins",
                color: "#fff",
                lineHeight: "28px",
                textAlign: "center",
              }}
            >
              Your Holdings
            </Typography>
            <CloseIcon
              onClick={handleOpenBoxClose}
              style={{ color: "#fff", cursor: "pointer" }}
            />
          </Box>
          {holidings > 0 ? (
            <>
              <Box
                display="flex"
                sx={{
                  border: "1px solid #fff",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box width="33.3%" borderRight="1px solid #fff">
                  <Typography
                    p={2}
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      fontFamily: "Poppins",
                      color: "#fff",
                      lineHeight: "28px",
                    }}
                  >
                    Stages
                  </Typography>
                </Box>
                <Box width="33.3%" borderRight="1px solid #fff">
                  <Typography
                    p={2}
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      fontFamily: "Poppins",
                      color: "#fff",
                      lineHeight: "28px",
                    }}
                  >
                    Claimable Tokens
                  </Typography>
                </Box>
                <Box width="33.3%">
                  <Typography
                    p={2}
                    sx={{
                      fontSize: "13px",
                      fontWeight: "700",
                      fontFamily: "Poppins",
                      color: "#fff",
                      lineHeight: "28px",
                    }}
                  >
                    Invested Amount
                  </Typography>
                </Box>
              </Box>

              {stagePerholidings.map(
                ({ holdingAmount, investedAmount, stage }, index) => {
                  return (
                    <>
                      <Box
                        key={index}
                        display="flex"
                        sx={{
                          border: "1px solid #fff",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Box width="33.3%" borderRight="1px solid #fff">
                          <Typography
                            p={2}
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              color: "#fff",
                              lineHeight: "28px",
                            }}
                          >
                            Stage {stage}
                          </Typography>
                        </Box>
                        <Box width="33.3%" borderRight="1px solid #fff">
                          <Typography
                            p={2}
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              color: "#fff",
                              lineHeight: "28px",
                            }}
                          >
                            {formatNumberWithCommas(
                              parseFloat(holdingAmount).toFixed(0)
                            )}{" "}
                            RETIK
                          </Typography>
                        </Box>
                        <Box width="33.3%">
                          <Typography
                            p={2}
                            sx={{
                              fontSize: "13px",
                              fontWeight: "400",
                              fontFamily: "Poppins",
                              color: "#fff",
                              lineHeight: "28px",
                            }}
                          >
                            $
                            {formatNumberWithCommas(
                              parseFloat(investedAmount).toFixed(0)
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                }
              )}
            </>
          ) : (
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                fontFamily: "Poppins",
                color: "#fff",
                lineHeight: "28px",
                textAlign: "center",
              }}
            >
              You have no holdings yet!
            </Typography>
          )}

          {/* {stagePerholidings.map(({ holdingAmount, stage }, index) => {
            return (
              <Typography
                mb={1}
                key={index}
                sx={{
                  fontSize: "17px",
                  fontWeight: "700",
                  fontFamily: "Poppins",
                  color: "#fff",
                  lineHeight: "28px",
                }}
              >
                Stage {stage}: ${holdingAmount} RETIK
              </Typography>
             */}
        </Box>
      </Dialog>
      <Box position="relative" zIndex={1}>
        <Loading loading={loading} />
        <ToastNotify alertState={alertState} setAlertState={setAlertState} />
        <Container maxWidth="xl">
          <Grid container spacing={7} py={matches ? 4 : 4} px={!matches && 3}>
            <Grid
              item
              md={6}
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
              display="flex"
              pb={matches && 2}
            >
              <Box>
                <Hidden mdDown>
                  <Box display="flex" alignItems="center">
                    <a
                      href="https://linktr.ee/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linktree}
                        width="30px"
                        style={{ marginRight: "15px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://twitter.com/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={tweet}
                        width="30px"
                        style={{ marginRight: "15px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://t.me/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={tele}
                        width="30px"
                        style={{ marginRight: "15px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/@retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={youtube}
                        width="30px"
                        style={{ marginRight: "15px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://medium.com/@retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={med}
                        width="30px"
                        style={{ marginRight: "15px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://reddit.com/r/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaRedditAlien
                        style={{ fontSize: "30px", color: "#B57DFE" }}
                      />
                    </a>
                  </Box>
                </Hidden>
                <MianHeading mt={matches ? -3 : 3}>
                  Step Into The Future with{" "}
                  <span
                    style={{
                      background: "transparent",
                      backgroundImage:
                        "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Retik Finance
                  </span>{" "}
                </MianHeading>
                <Paragraph mt={4} pr={matches ? 0 : 2}>
                  Retik Finance innovates in DeFi with it's Futuristic DeFi
                  Debit Cards, Smart Crypto Payment Gateway, AI Powered Peer to
                  Peer (P2P) Lending and Multi Chain Non Custodial Highly
                  Secured DeFi Wallet, aiming to redefine global transactions.
                </Paragraph>
                <Box
                  mt={matches ? 4 : 9}
                  display="flex"
                  flexDirection={matches ? "column" : "row"}
                >
                  <Link
                    to="https://retik.com/whitepaper.pdf"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        background:
                          "linear-gradient(64.83deg, #30304e 15%, #27283c 60%)",
                        fontSize: matches ? "16px" : "20px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        textTransform: "capitalize",
                        fontFamily: "Poppins",
                        borderRadius: "18px",
                        marginRight: "20px",
                        width: matches ? "100%" : "170px",
                        height: "50px",
                      }}
                    >
                      Whitepaper
                    </Button>
                  </Link>
                  <Link
                    to="https://retik.com/audit"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        background:
                          "linear-gradient(64.83deg, #30304e 15%, #27283c 60%)",
                        fontSize: matches ? "16px" : "20px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        textTransform: "capitalize",
                        fontFamily: "Poppins",
                        borderRadius: "16px",
                        marginRight: "20px",
                        width: matches ? "100%" : "170px",
                        height: "50px",
                        mt: matches && 2,
                      }}
                    >
                      Audit
                    </Button>
                  </Link>
                </Box>
                <Hidden mdUp>
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={3}
                    justifyContent="center"
                  >
                    <a
                      href="https://linktr.ee/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linktree}
                        width="30px"
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://twitter.com/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={tweet}
                        width="30px"
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://t.me/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={tele}
                        width="30px"
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://www.youtube.com/@retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={youtube}
                        width="30px"
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://medium.com/@retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={med}
                        width="30px"
                        style={{ marginRight: "10px" }}
                        alt=""
                      />
                    </a>
                    <a
                      href="https://reddit.com/r/retikfinance"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaRedditAlien
                        style={{ fontSize: "30px", color: "#B57DFE" }}
                      />
                    </a>
                  </Box>
                </Hidden>
              </Box>
            </Grid>

            {/* Presale stage 1 Card data  */}

            <Grid item md={6} xs={12}>
              <Box
                pb={5}
                px={!matches && 5}
                sx={{
                  background: matches
                    ? "radial-gradient(54.8% 53% at 50% 50%, #151515 0, #15151500 100%), linear-gradient(152.97deg, #fff3, #fff0)"
                    : "transparent",
                  backdropFilter: "blur(30px)",
                  borderRadius: matches ? "16px" : "25px",
                  textAlign: "center",
                  border: matches ? "1px solid #414247" : "1px solid #fff",
                  // boxShadow:
                  //   "  rgba(255, 255, 255, 0.2) 0px 10px 36px 0px, rgba(255, 255, 255, 0.2) 0px 0px 0px 1px",
                }}
              >
                <>
                  <Box>
                    <Box
                      sx={{
                        // background: `url(${boxbg})`,
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      p={3}
                    >
                      <Typography
                        sx={{
                          fontSize: matches ? "24px" : "3rem",
                          fontWeight: "700",
                          fontFamily: "Poppins",
                          color: "#fff",
                        }}
                      >
                        <span
                          style={{
                            background: "transparent",
                            backgroundImage:
                              "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          Retik
                        </span>{" "}
                        Presale
                      </Typography>
                      <Typography
                        mb={5}
                        sx={{
                          fontSize: matches ? "24px" : "3rem",
                          fontWeight: "700",
                          fontFamily: "Poppins",
                          color: "#fff",
                          lineHeight: "28px",
                        }}
                      >
                        Stage{" "}
                        <span
                          style={{
                            background: "transparent",
                            backgroundImage:
                              "linear-gradient(90deg, rgba(109,216,212,1) 10%, rgba(243,137,192,1) 60%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                          }}
                        >
                          {currentPresaleID}
                        </span>
                      </Typography>
                      {/* <PresaleTimer time={presaleTimes} /> */}

                      {/* Progress bar */}
                      <StyledText
                        style={{
                          fontSize: matches ? "18px" : "24px",
                          fontWeight: "400",
                        }}
                      >
                        1 RETIK = ${parseFloat(tokenPrice).toFixed(3)}
                      </StyledText>

                      <StyledText
                        style={{
                          marginTop: "0px",
                          fontSize: matches ? "18px" : "24px",
                          fontWeight: "400",
                        }}
                      >
                        Next Stage Price = $
                        {parseFloat(nextStagePrice).toFixed(3)}
                      </StyledText>
                      {account ? (
                        <Typography
                          mt={1}
                          sx={{
                            fontSize: matches ? "16px" : "20px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                            color: "#FEC5A3",
                          }}
                        >
                          Your Holdings:{" "}
                          <span
                            onClick={handleOpenBox}
                            style={{
                              color: "#fff",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            $
                            {formatNumberWithCommas(
                              parseFloat(holidings).toFixed(0)
                            )}
                          </span>
                        </Typography>
                      ) : null}

                      <Box
                        sx={{
                          position: "relative",
                          background: "#fff",
                          borderRadius: "20px",
                          margin: "15px 0",
                          height: 30,
                          width: "100%",
                          overflow: "hidden",
                        }}
                        mt={1}
                      >
                        <div
                          style={{
                            backgroundImage:
                              "linear-gradient(273.16deg, #6DD8D4 20.63%, #ff94ff 75.15%)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: 30,
                            opacity: 1,
                            transition: "1s ease 0.3s",
                            width: `${+progress > 0 ? progress : 0}%`,
                            // width: "40%",
                          }}
                        ></div>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 5,
                            left: "45%",
                            color: "#000",
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          {+progress > 0
                            ? parseFloat(progress).toFixed(2)
                            : "0.00"}
                          %
                        </Box>
                      </Box>

                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <StyledText
                            style={{
                              fontSize: matches ? "18px" : "24px",
                              fontWeight: "400",
                            }}
                          >
                            USDT Raised:{" "}
                            <span
                              style={{ color: "#FEC5A3", fontWeight: "700" }}
                            >
                              $
                              {formatNumberWithCommas(
                                parseFloat(totalRaised).toFixed(0)
                              )}{" "}
                              / ${formatNumberWithCommas(usdtSoldHardcap)}
                            </span>
                          </StyledText>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <StyledText
                            style={{
                              fontSize: matches ? "18px" : "24px",
                              fontWeight: "400",
                            }}
                          >
                            Tokens Sold:{" "}
                            <span
                              style={{ color: "#FEC5A3", fontWeight: "700" }}
                            >
                              {formatNumberWithCommas(
                                parseFloat(soldToken).toFixed(0)
                              )}{" "}
                              / {tokenSoldHardcap.toLocaleString()}
                            </span>
                          </StyledText>
                        </Box>
                      </Box>
                    </Box>

                    <Box mx={3} display="flex" justifyContent="space-between">
                      <Box
                        onClick={() => {
                          setToken("ETH");
                          handlebgClick();
                        }}
                        sx={{
                          width: "31%",
                          height: "50px",
                          border: "1px solid #fff",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",

                          background: isButtonClicked
                            ? "#C582FE"
                            : "transparent",
                        }}
                      >
                        <img
                          src={eth}
                          width={matches ? "68px" : "78px"}
                          alt=""
                        />
                      </Box>
                      <Box
                        onClick={() => {
                          setToken("USDT");
                          handlebgClick1();
                        }}
                        sx={{
                          width: "31%",
                          height: "50px",
                          border: "1px solid #fff",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          background: isButtonClicked1
                            ? "#C582FE"
                            : "transparent",
                        }}
                      >
                        <img
                          src={usdt}
                          width={matches ? "68px" : "78px"}
                          alt=""
                        />
                      </Box>
                      <Link
                        to="https://retik.com/how-to-buy#card"
                        target="_blank"
                        style={{ textDecoration: "none", width: "31%" }}
                      >
                        <Box
                          // onClick={() => setToken("USDC")}
                          sx={{
                            width: "100%",
                            height: "50px",
                            border: "1px solid #fff",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#C582FE",
                            },
                          }}
                        >
                          <CreditCardIcon
                            sx={{
                              color: "#fff",
                              fontSize: matches ? "25px" : "32px",
                            }}
                          />
                          <Typography
                            pl={1}
                            fontWeight="400"
                            fontSize={matches ? "14px" : "16px"}
                            color="#fff"
                          >
                            CARD
                          </Typography>
                          {/* <img
                          src={usdc}
                          width={matches ? "68px" : "78px"}
                          alt=""
                        /> */}
                        </Box>
                      </Link>
                    </Box>
                    {/* <Link
                      to="https://retik.com/how-to-buy#card"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        mt={1}
                        fontWeight="400"
                        fontSize={"16px"}
                        color="#fff"
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        Buy with Card
                      </Typography>
                    </Link> */}
                    <Box px={3}>
                      <Box py={1} textAlign="start" mt={1}>
                        <StyledText style={{ fontWeight: "400" }}>
                          Amount in{" "}
                          <span style={{ fontWeight: "700" }}>
                            {token === "USDT"
                              ? "USDT"
                              : token === "USDC"
                              ? "USDC"
                              : "Eth"}
                          </span>{" "}
                          You Pay:
                        </StyledText>
                        <Box
                          mt={1}
                          sx={{
                            bgcolor: "#F1F4F6",
                            borderRadius: "10px",
                            width: "100%",
                            height: "53px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <input
                            style={{
                              height: "40px",
                              width: "100%",
                              fontSize: "17px",
                              fontWeight: "500",
                              textAlign: "left",
                              color: "#000000",
                              fontFamily: "Poppins",
                              backgroundColor: "transparent",
                              paddingLeft: "15px",
                              border: "none",
                              outline: "none",
                            }}
                            type="number"
                            placeholder="Enter Amount"
                            value={enteredAmount}
                            name="usdt"
                            onChange={onChangeHandler}
                          />

                          <Box display="flex" alignItems="center">
                            <StyledButton
                              width="50px"
                              onClick={() => {
                                setEnteredAmount(balance);
                              }}
                              style={{
                                borderRadius: "10px",
                                height: "38px",
                                boxShadow: "none",
                              }}
                            >
                              Max
                            </StyledButton>
                            <img
                              alt=""
                              src={
                                token === "USDT"
                                  ? usdt1
                                  : token === "USDC"
                                  ? usdc1
                                  : eth1
                              }
                              style={{
                                width: "27px",
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            />
                          </Box>
                        </Box>

                        <Box mt={1}>
                          <StyledText style={{ fontWeight: "400" }}>
                            Amount in{" "}
                            <span style={{ fontWeight: "700" }}>RETIK</span> You
                            Receive:
                          </StyledText>
                          <Box
                            mt={0.5}
                            sx={{
                              bgcolor: "#F1F4F6",
                              borderRadius: "10px",
                              width: "100%",
                              height: "53px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="number"
                              style={{
                                height: "40px",
                                width: "100%",
                                fontSize: "17px",
                                fontWeight: "500",
                                textAlign: "left",
                                color: "#000000",
                                fontFamily: "Poppins",
                                backgroundColor: "transparent",
                                paddingLeft: "15px",
                                border: "none",
                                outline: "none",
                              }}
                              placeholder="0"
                              name="retik"
                              readOnly
                              value={enterToken}
                              // onChange={onChangeHandler}
                              // onChange={(e) => setEnterToken(e.target.value)}
                              // value={enterToken}
                            />
                            <Box pr={1} display="flex" alignItems="center">
                              <img
                                src={logo}
                                alt=""
                                style={{
                                  marginRight: "4px",
                                  width: "20px",
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box mb={1}>
                        {!account ? (
                          <StyledButton
                            width="100%"
                            onClick={async () => open()}
                          >
                            Connect Wallet
                          </StyledButton>
                        ) : (
                          <StyledButton
                            width="100%"
                            onClick={() => buyHandler()}
                          >
                            Buy Now
                          </StyledButton>
                        )}
                      </Box>
                      {/* <StyledButton width="100%" onClick={handleOpenClaim}>
                        Claim Amount
                      </StyledButton> */}
                      <Link
                        to="https://retik.com/troubleshoot"
                        target="_blank"
                        style={{ textDecoration: "none", width: "100%" }}
                      >
                        <Box
                          mt={1}
                          sx={{
                            width: "100%",
                            height: "32px",
                            border: "2px solid #4B4C59",
                            background: "#414141",
                            borderRadius: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#fff",
                            fontSize: matches ? "14px" : "17px",
                            cursor: "pointer",
                          }}
                        >
                          <HiOutlineQuestionMarkCircle
                            style={{ fontSize: "24px", marginRight: "7px" }}
                          />{" "}
                          Getting Error? Click Here
                        </Box>
                      </Link>
                      <Box
                        mt={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Link
                          to="https://retik.com/how-to-buy"
                          target="_blank"
                          style={{ textDecoration: "none", width: "45%" }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "32px",
                              border: "2px solid #4B4C59",
                              background: "#414141",
                              borderRadius: "12px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                              fontSize: matches ? "14px" : "17px",
                              cursor: "pointer",
                            }}
                          >
                            <HiOutlineQuestionMarkCircle
                              style={{ fontSize: "24px", marginRight: "7px" }}
                            />{" "}
                            How To Buy?
                          </Box>
                        </Link>

                        <Link
                          to="https://retik.com/win-333k"
                          target="_blank"
                          style={{ textDecoration: "none", width: "45%" }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "32px",
                              border: "2px solid #4B4C59",
                              background: "#414141",
                              borderRadius: "12px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                              fontSize: matches ? "14px" : "17px",
                              cursor: "pointer",
                            }}
                          >
                            <BsRocketTakeoff
                              style={{ fontSize: "17px", marginRight: "7px" }}
                            />{" "}
                            Win $333k
                          </Box>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
