import React from "react";
import {
  Container,
  Hidden,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";

// import { AppContext } from "../utils";
import { StyledText } from "./SmallComponents/AppComponents";
import { ExampleButton } from "./SmallComponents/StyledWalletButton";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#1C1D23 !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

const mobileDataarr = [
  {
    name: "Ecosystem",
    link: "/#ecosystem",
  },
  {
    name: "Tokenomics",
    link: "/#tokenomics",
  },
  {
    name: "Win $333K",
    link: "https://retik.com/win-333k",
  },
  {
    name: "Whitepaper",
    link: "https://retik.com/whitepaper.pdf",
  },
  {
    name: "How To Buy",
    link: "https://retik.com/how-to-buy",
  },
  {
    name: "Contact Us",
    link: "https://retik.com/contact-us",
  },
];
export default function Header() {
  // const { account, connect, disconnect } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches = useMediaQuery("(max-width:950px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" alignItems="center" my={5}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Typography
            ml={1}
            sx={{
              fontWeight: "700",
              fontSize: matches ? "24px" : "27px",
              color: "#fff",
              fontFamily: "Poppins",
            }}
          >
            RETIK FINANCE
          </Typography>
        </Link>
      </Box>
      <List>
        {mobileDataarr.map(({ name, link }, index) => (
          <ListItem
            button
            style={{
              justifyContent: "center",
            }}
            key={index}
          >
            <HashLink smooth to={link} style={{ textDecoration: "none" }}>
              <ListItemText
                style={{
                  textTransform: "capitalize",
                  textAlign: "center",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                {name}
              </ListItemText>
            </HashLink>
          </ListItem>
        ))}
      </List>
      <Box mt={3} display="flex" justifyContent="center">
        <ExampleButton />
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        height="92px"
        width="100%"
        position="relative"
        zIndex={1}
        py={matches ? 1.5 : 3}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={!matches && 2}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                ml={1}
                sx={{
                  fontWeight: "700",
                  fontSize: matches ? "24px" : "27px",
                  color: "#fff",
                  fontFamily: "Poppins",
                }}
              >
                RETIK FINANCE
              </Typography>
            </Link>
            <Box
              display="flex"
              justifyContent={matches ? "end" : "space-between"}
              alignItems="center"
            >
              <Hidden mdDown>
                <HashLink
                  smooth
                  to="/#ecosystem"
                  style={{ textDecoration: "none" }}
                >
                  <StyledText mr={4}>Ecosystem</StyledText>
                </HashLink>
                <HashLink
                  smooth
                  to="/#tokenomics"
                  style={{ textDecoration: "none" }}
                >
                  <StyledText mr={4}>Tokenomics</StyledText>
                </HashLink>
                <Link
                  to="https://retik.com/win-333k"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <StyledText mr={4}>Win $333K</StyledText>
                </Link>
                <Link
                  to="https://retik.com/whitepaper.pdf"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <StyledText mr={4}>Whitepaper</StyledText>
                </Link>
                <Link
                  to="https://retik.com/how-to-buy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <StyledText mr={4}>How To Buy</StyledText>
                </Link>
                <Link
                  to="https://retik.com/contact-us"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <StyledText>Contact Us</StyledText>
                </Link>
              </Hidden>

              <Hidden mdUp>
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      style={{ zIndex: 1 }}
                    >
                      <MenuIcon
                        style={{
                          fontSize: "30px",
                          cursor: "pointer",
                          color: "#fff",
                        }}
                      ></MenuIcon>
                    </Button>
                    <Paper>
                      <SwipeableDrawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </Paper>
                  </React.Fragment>
                ))}
              </Hidden>
            </Box>
            <Hidden mdDown>
              <ExampleButton />
            </Hidden>
          </Box>
        </Container>
      </Box>
    </>
  );
}
