import React from "react";
import { Add, Remove } from "@mui/icons-material";
import giveaway from "../Images/giveaway.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function Faqs() {
  const matches = useMediaQuery("(max-width:950px)");
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const faq = [
    {
      id: 1,
      question: "What is Retik Finance?",
      answer:
        "Retik Finance is a DeFi ecosystem that provides a variety of financial tools and services, including DeFi debit cards, smart payment gateways, P2P lending, and a secure wallet. It aims to redefine how people conduct financial transactions in the digital age.",
    },
    {
      id: 2,
      question: "What is $RETIK Token?",
      answer:
        "$RETIK is a community-driven governance token for the Retik Finance platform, allowing token holders to participate in decision-making processes and help shape the future of the ecosystem.s",
    },
    {
      id: 3,
      question: "How & Where can I buy $RETIK token?",
      answer: (
        <Box>
          As of now, Retik Finance ($RETIK) is in its presale phase, which means
          it is not yet available on traditional cryptocurrency exchanges or
          DeFi platforms like Uniswap. Beware of potential scams using Retik's
          name.
          <Box mt={3}>
            The only legitimate way to participate in the Retik presale is
            through our official website at{" "}
            <a
              href="https://retik.com"
              target="_blank"
              style={{ textDecoration: "underline", color: "#97979A" }}
              rel="noreferrer"
            >
              https://retik.com.
            </a>{" "}
            Please exercise caution and ensure you're on the official platform
            to avoid fraudulent schemes.
          </Box>
          <Box mt={3}>
            For detailed instructions on how to buy $RETIK tokens, please
            consult our "How to Buy" guide at{" "}
            <a
              href="https://retik.com/how-to-buy"
              target="_blank"
              style={{ textDecoration: "underline", color: "#97979A" }}
              rel="noreferrer"
            >
              https://retik.com/how-to-buy.
            </a>
          </Box>
        </Box>
      ),
    },
    {
      id: 4,
      question: "When and where will I receive my $RETIK tokens?",
      answer:
        "You will be able to claim your $RETIK tokens after the presale concludes. This claiming process will take place on Retik's official website.",
    },
    {
      id: 5,
      question: "How many presale stages are there?",
      answer: (
        <Box>
          Retik Finance's presale consists of 10 stages. For additional details,
          please visit:{" "}
          <a
            href="https://retik.com/presale"
            target="_blank"
            style={{ textDecoration: "underline", color: "#97979A" }}
            rel="noreferrer"
          >
            {" "}
            https://retik.com/presale.
          </a>
        </Box>
      ),
    },
    {
      id: 6,
      question: "When and where will $RETIK launch?",
      answer:
        "Following the presale, $RETIK will launch on multiple centralized exchanges, including at least two top-tier exchanges.Stay tuned for official announcements about the specific launch date and trading platforms by following our social media channels.",
    },

    {
      id: 7,
      question: "When can I apply for my Retik DeFi Debit Card?",
      answer: (
        <Box>
          Retik's DeFi debit cards will be available for pre-order once our app
          is live on the App Store and Google Play Store. For more information
          download our{" "}
          <a
            href="https://retik.com/whitepape.pdf"
            target="_blank"
            style={{ textDecoration: "underline", color: "#97979A" }}
            rel="noreferrer"
          >
            {" "}
            whitepaper.
          </a>
        </Box>
      ),
    },
    {
      id: 8,
      question: "My wallet is compromised/hacked, what should I do?",
      answer: (
        <Box>
          If you suspect that your wallet has been compromised, it's essential
          to take immediate action to protect your assets. Please follow these
          steps:
          <Box mt={3}>
            <span style={{ fontWeight: "700" }}>
              Never Share Your Seed Phrase or Private Key:
            </span>{" "}
            Under no circumstances should you share your Seed Phrase or Private
            Key with anyone. These are sensitive and should be kept secure.
          </Box>
          <Box mt={3}>
            <span style={{ fontWeight: "700" }}>Contact Us:</span> Reach out to
            us at:{" "}
            <a
              href="https://retik.com/help"
              target="_blank"
              style={{ textDecoration: "underline", color: "#97979A" }}
              rel="noreferrer"
            >
              {" "}
              https://retik.com/help
            </a>{" "}
            to report the issue and seek guidance on how to proceed.
          </Box>
          <Box mt={3}>
            <span style={{ fontWeight: "700" }}>Important Note:</span>
            While we can assist you in claiming $RETIK tokens in the unfortunate
            event of your wallet being compromised or hacked, it's crucial to
            understand that we cannot recover lost funds. Protecting your assets
            remains a top priority.
          </Box>
          <Box mt={3}>
            <span style={{ fontWeight: "700" }}>Beware of Scammers:</span>
            Remember that we will never ask for your private details, such as
            your Seed Phrase or Private Key. Be cautious of potential scams and
            always verify the authenticity of the support you're engaging with.
          </Box>
        </Box>
      ),
    },
  ];
  return (
    <>
      <Container maxWidth="lg">
        <Box my={2} mt={matches ? 5 : 0} id="giveaway">
          <Box
            sx={{
              background: "#FEC5A3",
              p: matches ? 3 : 2,
              borderRadius: "26px",
            }}
          >
            <Grid container spacing={5} position="relative" zIndex={1}>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography
                  pl={!matches && 5}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: matches ? "27px" : "40px",
                    lineHeight: "122.2%",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  Join The Giveaway!
                </Typography>
                <Typography
                  pl={!matches && 5}
                  mt={2}
                  mb={2}
                  sx={{
                    fontWeight: 400,
                    fontFamily: "'Poppins'",
                    fontSize: matches ? "16px" : "18px",
                    lineHeight: "27px",
                    color: "#000",
                    textAlign: "left",
                  }}
                >
                  The more $RETIK you purchase and the more you participate in
                  Gleam, the more tickets you earn, and the higher your chances
                  of walking away with these extraordinary prizes!
                </Typography>
                <Link
                  to="https://retik.com/win-333k"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    width: matches ? "100%" : "30%",
                  }}
                >
                  <Button
                    sx={{
                      background: "#000",
                      width: "170px",
                      borderRadius: "16px",
                      height: "50px",
                      color: "#fff",
                      ml: !matches && 5,
                      "&:hover": {
                        background: "#000",
                      },
                    }}
                  >
                    Join the Giveaway
                  </Button>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="flex-end"
              >
                <img src={giveaway} width={matches ? "100%" : "90%"} alt="" />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Box py={10} position="relative" zIndex={1} id="faqs">
        {/* <Box
          sx={{
            width: "0px",
            height: "0px",
            boxShadow: matches ? "" : "0px 350px 330px 330px rgb(72,70,131)",
            borderRadius: "50%",
            position: "absolute",
            zIndex: 0,
            top: "0%",
            right: "0%",
          }}
        /> */}
        <Container maxWidth="md">
          <Typography
            position="relative"
            zIndex={1}
            mt={2}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "40px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            FAQ
          </Typography>
          <Typography
            position="relative"
            zIndex={1}
            mt={2}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            You may have questions, we have the answers.
          </Typography>
          <Box sx={{ width: "100%", pb: 10 }} position="relative" zIndex={1}>
            {faq.map((faq, i) => {
              const { id, question, answer } = faq;
              return (
                <Box key={i} mt={5} sx={{ width: "100%" }}>
                  <Accordion
                    expanded={expanded === `{panel${id}}`}
                    onChange={handleChange(`{panel${id}}`)}
                    style={{
                      borderBottom: "1px solid #bdbdcb",
                      background: "transparent",
                      width: "100%",
                      boxShadow: "none",
                      borderRadius: "none",
                      m: "auto",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `{panel${id}}` ? (
                          <Remove
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                            }}
                          />
                        ) : (
                          <Add
                            style={{
                              color: "#fff",
                              fontSize: "30px",
                            }}
                          />
                        )
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        px={2}
                        py={1}
                        fontSize={matches ? "16px" : " 23px"}
                        textAlign="left"
                        fontFamily="Poppins"
                        fontWeight="700"
                        color="#fff"
                      >
                        {question}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={2}
                        pb={3}
                        fontSize={matches ? "14px" : "18px"}
                        textAlign="left"
                        fontFamily="Poppins"
                        fontWeight="400"
                        color="#97979A"
                      >
                        {answer}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Box>
    </>
  );
}
