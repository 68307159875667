import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import image3 from "../Images/image3.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";

export default function TierSystem() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box py={matches ? 5 : 0} position="relative" zIndex={1}>
      {/* <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: "0px 220px 800px 200px rgb(87,67,119)",
          borderRadius: "50%",
          position: "absolute",
          top: matches ? "20%" : "-55%",
          left: "0%",
        }}
      /> */}
      <Container maxWidth="xl">
        {/* <Grid container spacing={7} position="relative" zIndex={1}>
          <Grid item xs={12} md={6}>
            <img src={image3} width="100%" alt="" />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "30px" : "40px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                Staking and
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(273.16deg, #FF94FF 20.63%, #6DD8D4 75.15%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {" "}
                  Tier System
                </span>
              </Typography>
              <Typography
                mt={2}
                mb={4}
                sx={{
                  fontWeight: 400,
                  fontFamily: "'Poppins'",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                Stake $RETIK to earn rewards from our massive token pools and a
                share of the platform revenue. Maximize your benefits by
                climbing the tier system to receive higher APY, lower fees, and
                greater odds of winning raffle prizes with our ticket
                multipliers.
              </Typography>

              <StyledButton width="160px">Buy $RETIK</StyledButton>
            </Box>
          </Grid>
        </Grid> */}

        <Grid
          container
          spacing={3}
          position="relative"
          zIndex={1}
          px={!matches && 12}
        >
          <Grid item xs={12} md={4} width="100%">
            <Box
              sx={{
                border: "1px solid #BDBDBF",
                borderRadius: "16px",
                py: 2,
                px: 3,
                // width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#BDBDBF",
                  fontFamily: "'Poppins'",
                  fontWeight: "700",
                  fontSize: matches ? "20px" : "30px",
                }}
              >
                SILVER
              </Typography>
              <Typography
                mt={0.5}
                sx={{
                  color: "#fff",
                  fontFamily: "'Poppins'",
                  fontWeight: "700",
                  fontSize: matches ? "20px" : "26px",
                }}
              >
                2.5% Cashback
              </Typography>
              <Typography
                mt={0.5}
                sx={{
                  color: "#BDBDBF",
                  fontFamily: "'Poppins'",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Card cost: $249
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} width="100%">
            <Box
              sx={{
                border: "1px solid #FAC2A1",
                borderRadius: "16px",
                py: 2,
                px: 3,
                // width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#FAC2A1",
                  fontFamily: "'Poppins'",
                  fontWeight: "700",
                  fontSize: matches ? "20px" : "30px",
                }}
              >
                GOLD
              </Typography>
              <Typography
                mt={0.5}
                sx={{
                  color: "#fff",
                  fontFamily: "'Poppins'",
                  fontWeight: "700",
                  fontSize: matches ? "20px" : "26px",
                }}
              >
                3.75% Cashback
              </Typography>
              <Typography
                mt={0.5}
                sx={{
                  color: "#BDBDBF",
                  fontFamily: "'Poppins'",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Card cost: $499
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} width="100%">
            <Box
              sx={{
                border: "1px solid #B645EB",
                borderRadius: "16px",
                py: 2,
                px: 3,
                // width: "100%",
              }}
            >
              <Typography
                sx={{
                  background: "transparent",
                  backgroundImage:
                    "linear-gradient(273.16deg, #FF94FF 20.63%, #6DD8D4 75.15%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "'Poppins'",
                  fontWeight: "700",
                  fontSize: matches ? "20px" : "30px",
                }}
              >
                Diamond
              </Typography>
              <Typography
                mt={0.5}
                sx={{
                  color: "#fff",
                  fontFamily: "'Poppins'",
                  fontWeight: "700",
                  fontSize: matches ? "20px" : "26px",
                }}
              >
                5% Cashback
              </Typography>
              <Typography
                mt={0.5}
                sx={{
                  color: "#BDBDBF",
                  fontFamily: "'Poppins'",
                  fontWeight: "400",
                  fontSize: "16px",
                }}
              >
                Card cost: $999
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
