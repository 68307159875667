import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import EqualizerRoundedIcon from "@mui/icons-material/EqualizerRounded";
import fig1 from "../Images/fig1.png";
import fig2 from "../Images/fig2.png";
import fig3 from "../Images/fig3.png";
import fig4 from "../Images/fig4.png";
import fig5 from "../Images/fig5.png";
import fig6 from "../Images/fig6.png";
import bitmart from "../Images/bitmart.png";
import table from "../Images/table.png";
import Partners from "./Partners";
import Utility from "./Utility";
const data = [
  {
    icon: <img src={fig1} width="30px" alt="" />,
    title: "DeFi Debit Cards",
    description:
      "Prioritize your financial privacy with anonymous transactions, eliminating the need for KYC procedures, and ensure security with Retik DeFi Debit Cards",
  },
  {
    icon: <img src={fig2} width="30px" alt="" />,
    title: "Payment Gateway",
    description:
      "Seamlessly accept cryptocurrencies in your online business, expanding payment options for your customers.",
  },
  {
    icon: <img src={fig3} width="30px" alt="" />,
    title: "DeFi Wallet",
    description:
      "A secure, non-custodial wallet supporting multiple blockchains, providing you full control over your assets.",
  },
  {
    icon: <img src={fig4} width="25px" alt="" />,
    title: "Swap Aggregator",
    description:
      "Effortlessly find the most favorable rates by instantly comparing quotes and fees across various decentralized exchanges (DEXes)",
  },
  {
    icon: <img src={fig5} width="30px" alt="" />,
    title: "AI-Powered P2P Lending",
    description:
      "Let our powerful AI analyze lending options for you, ensuring you get the best and most profitable lending solutions.",
  },
  {
    icon: <img src={fig6} width="30px" alt="" />,
    title: "Perpetual Futures & Options",
    description:
      "Engage in long and put options directly from App, simplifying your trading experience across different assets.",
  },
];
export default function Choose() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(max-width:1320px)");

  return (
    <Box py={matches ? 5 : 10} position="relative" zIndex={1} id="ecosystem">
      {/* <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: "0px 250px 250px 240px rgb(98,111,67) ",
          borderRadius: "50%",
          position: "absolute",
          top: "0%",
          right: "10%",
        }}
      />

      <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: "0px 220px 800px 200px rgb(87,67,119)",
          borderRadius: "50%",
          position: "absolute",
          bottom: "60%",
          left: "0%",
        }}
      /> */}
      <Container maxWidth="lg">
        <Box position="relative" zIndex={1} pt={10}>
          <Typography
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "36px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            Retik
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {" "}
              Ecosystem
            </span>
          </Typography>
          <Typography
            mt={2}
            px={!matches && 10}
            sx={{
              fontWeight: 400,
              fontFamily: "'Poppins'",
              fontSize: "16px",
              lineHeight: "27px",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            Retik Ecosystem: A comprehensive DeFi solution, encompassing a
            non-custodial wallet, swap aggregator, DeFi debit cards, payment
            gateway, AI-powered P2P lending, and perpetual futures/options
            trading for seamless crypto management.
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={matches ? 5 : 3}
          mt={7}
          position="relative"
          zIndex={1}
          px={!matches && 13}
        >
          {data.map(({ icon, title, description }, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={index}
              width="100%"
              mt={matches && 2}
            >
              <Box
                sx={{
                  background:
                    "linear-gradient(64.83deg, #30304e 15%, #27283c 60%);",
                  height: matches ? "100%" : matches1 ? "300px" : "230px",
                  border: "1px solid #fff ",
                  borderRadius: "25px",
                  px: matches ? 2 : 5,
                  pt: matches ? 3 : 5,
                  pb: !matches && 5,
                }}
              >
                {icon}
                <Typography
                  my={1}
                  sx={{
                    fontFamily: "'Poppins'",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: matches ? "26px" : "30px",
                    lineHeight: "122.2%",
                    color: "#FFFFFF",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontFamily: "'Poppins'",
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#BDBDCB",
                  }}
                >
                  {description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        {/* <Partners />
        <Box my={2} mt={matches ? 10 : 15} position="relative">
          <img src={table} width="100%" alt="" />
        </Box>
        <Grid container spacing={7} mt={8} position="relative" zIndex={1}>
          <Grid item xs={12} md={6} width="100%">
            <Box
              sx={{
                background: "transparent",
                // height: "100%",
                border: "1px solid #fff ",
                borderRadius: "16px",
                p: 3,
              }}
            >
              <Typography
                mb={3}
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "28px" : "40px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                Member of Binance accelerator program
              </Typography>
              <Typography
                my={2}
                sx={{
                  fontWeight: 700,
                  fontFamily: "'Poppins'",
                  fontSize: matches ? "24px" : "28px",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                <span style={{ color: "#F0B90B" }}>BNB CHAIN</span> KICKSTART
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} width="100%">
            <Box
              sx={{
                background: "transparent",
                // height: "100%",
                border: "1px solid #fff ",
                borderRadius: "16px",
                p: 3,
              }}
            >
              <Typography
                mb={2}
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "28px" : "40px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                $RETIK Confirmed Exchange Listing
              </Typography>
              <img src={bitmart} width="167px" alt="" />
            </Box>
          </Grid>
        </Grid> */}
      </Container>
    </Box>
  );
}
