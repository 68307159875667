import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Marquee from "react-fast-marquee";
import img1 from "../Images/img1.png";
import mr1 from "../Images/mr1.png";
import mr2 from "../Images/mr2.png";
import mr3 from "../Images/mr3.png";
import mr4 from "../Images/mr4.png";
import mr5 from "../Images/mr5.png";
import mr6 from "../Images/mr6.png";
import mr7 from "../Images/mr7.png";
import mr8 from "../Images/mr8.png";
import mr9 from "../Images/mr9.png";
import mr10 from "../Images/mr10.png";
import nmr1 from "../Images/nmr1.png";
import nmr2 from "../Images/nmr2.png";
import nmr4 from "../Images/nmr4.png";
import nmr5 from "../Images/nmr5.png";
import nmr6 from "../Images/nmr6.png";

export default function FeaturedIn() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box py={matches ? 2 : 4} mt={3} position="relative" zIndex={1}>
      <Typography
        mb={2}
        sx={{
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "122.2%",
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        Featured In
      </Typography>
      <Box mt={3} position="relative" zIndex={1}>
        <Marquee
          speed={50}
          pauseOnClick={true}
          pauseOnHover={true}
          gradient={false}
        >
          <Container maxWidth="xl">
            <Box style={{ display: "flex", alignItems: "center" }}>
              <img
                src={img1}
                width={matches ? "5%" : "8%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={mr1}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={mr2}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={mr3}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={mr4}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={mr5}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={nmr5}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
              <img
                src={nmr6}
                width={matches ? "5%" : "10%"}
                style={{ marginRight: matches ? "70px" : "44px" }}
                alt=""
              />
            </Box>
          </Container>
        </Marquee>
        <Box mt={3} position="relative" zIndex={1}>
          <Marquee
            speed={50}
            pauseOnClick={true}
            pauseOnHover={true}
            gradient={false}
            direction="right"
          >
            <Container maxWidth={matches ? "sm" : "xl"}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={mr6}
                  width={matches ? "8%" : "10%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />
                <img
                  src={mr7}
                  width={matches ? "5%" : "10%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />
                <img
                  src={mr8}
                  width={matches ? "5%" : "12%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />{" "}
                <img
                  src={mr9}
                  width={matches ? "5%" : "12%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />
                <img
                  src={nmr1}
                  width={matches ? "5%" : "8%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />
                <img
                  src={nmr2}
                  width={matches ? "5%" : "8%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />
                <img
                  src={nmr4}
                  width={matches ? "5%" : "10%"}
                  style={{ marginRight: matches ? "70px" : "44px" }}
                  alt=""
                />
                <img src={mr10} width={matches ? "5%" : "8%"} alt="" />
              </Box>
            </Container>
          </Marquee>
        </Box>
      </Box>
    </Box>
  );
}
