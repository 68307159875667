import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import image5 from "../Images/image5.png";
import competition from "../Images/competition.png";
import { StyledButton } from "../components/SmallComponents/AppComponents";

export default function Utility() {
  const matches = useMediaQuery("(max-width:950px)");
  return (
    <Box pt={matches ? 5 : 15} position="relative" zIndex={1}>
      {/* <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: "rgb(113,65,83) 0px 260px 360px 300px",
          borderRadius: "50%",
          position: "absolute",
          top: "10%",
          left: "0%",
        }}
      />
      <Box
        sx={{
          width: "0px",
          height: "0px",
          boxShadow: "rgb(255,207,110,0.4) 0px 260px 260px 200px",
          borderRadius: "50%",
          position: "absolute",
          top: "5%",
          right: "0%",
        }}
      /> */}
      <Container maxWidth="xl">
        <Grid
          container
          spacing={7}
          position="relative"
          zIndex={1}
          px={!matches && 10}
        >
          {!matches && (
            <Grid item xs={12} md={6}>
              <img src={image5} width={matches ? "100%" : "90%"} alt="" />
            </Grid>
          )}

          <Grid
            item
            md={6}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "25px" : "36px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Real-World{" "}
                </span>
                Crypto Adoption
              </Typography>
              {matches && (
                <img
                  src={image5}
                  style={{ marginTop: "30px", marginBottom: "30px" }}
                  width="100%"
                  alt=""
                />
              )}
              <Typography
                mt={2}
                mb={4}
                sx={{
                  fontWeight: 400,
                  fontFamily: "'Poppins'",
                  fontSize: matches ? "16px" : "1.125rem",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                Retik Finance is committed to bridging the gap between the
                crypto realm and real-life utility. Our mission extends beyond
                digital transactions, as we believe in bringing cryptocurrency
                to the forefront of everyday experiences. We understand that
                true adoption goes beyond the virtual realm, and that's why
                we're pioneering real-life integration like never before.
              </Typography>

              {/* <StyledButton width="160px">Buy $RETIK</StyledButton> */}
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Typography
        mt={15}
        sx={{
          fontFamily: "'Poppins'",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: matches ? "30px" : "40px",
          lineHeight: "122.2%",
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        What is
        <span
          style={{
            background: "transparent",
            backgroundImage:
              "linear-gradient(90deg, rgba(253,143,245,1) 10%, rgba(216,189,132,1) 60%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {" "}
          Retik?
        </span>
      </Typography>
      <Container maxWidth="lg">
        <Typography
          mt={2}
          px={!matches && 10}
          sx={{
            fontWeight: 400,
            fontFamily: "'Poppins'",
            fontSize: "16px",
            lineHeight: "27px",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          $RETIK stands as a dynamic DeFi token built upon the Ethereum mainnet,
          adhering to the ERC20 standard. With a total supply of 1 billion
          ($1,000,000,000), $RETIK powers the Retik Finance ecosystem, driving
          decentralized transactions and fueling the innovative financial
          solutions that define our platform's essence.
        </Typography>
      </Container>

      {/* <Box my={2} mt={matches ? 10 : 15} position="relative">
          <img src={competition} width="100%" alt="" />
        </Box>
        <Grid
          container
          spacing={7}
          mt={matches ? 5 : 8}
          position="relative"
          zIndex={1}
        >
          <Grid item xs={12} md={6} width="100%">
            <Box
              sx={{
                background:
                  "linear-gradient(90deg, rgba(48,48,78,1) 0%, rgba(39,40,60,1) 50%)",
                // height: "100%",
                border: "1px solid gray ",
                borderRadius: "16px",
                p: 4,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "30px" : "40px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                $RETIK
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(273.16deg, #FF94FF 20.63%, #6DD8D4 75.15%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {" "}
                  Token
                </span>
              </Typography>
              <Typography
                mt={2}
                sx={{
                  fontWeight: 400,
                  fontFamily: "'Poppins'",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                Platform-wide currency utilised for every transaction, including
                purchases, staking, and earning multipliers with built-in
                deflation.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} width="100%">
            <Box
              sx={{
                background:
                  "linear-gradient(90deg, rgba(48,48,78,1) 0%, rgba(39,40,60,1) 50%)",
                // height: "100%",
                border: "1px solid gray ",
                borderRadius: "16px",
                p: 4,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: matches ? "30px" : "40px",
                  lineHeight: "122.2%",
                  color: "#FFFFFF",
                }}
              >
                RETIK
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(273.16deg, #FF94FF 20.63%, #6DD8D4 75.15%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {" "}
                  NFT Ticket
                </span>
              </Typography>
              <Typography
                mt={2}
                sx={{
                  fontWeight: 400,
                  fontFamily: "'Poppins'",
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                }}
              >
                Platform-wide currency utilised for every transaction, including
                purchases, staking, and earning multipliers with built-in
                deflation.
              </Typography>
            </Box>
          </Grid>
        </Grid> */}
    </Box>
  );
}
