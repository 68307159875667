import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation } from "swiper/modules";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, useMediaQuery } from "@mui/material";

function StyledSwiper({ children }) {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ArrowForwardIosIcon
          className="swiper-button-prev"
          sx={{
            background: "transparent",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "1px solid #fff",
            color: "#fff",
            textAlign: "center",
            padding: "7px",
            cursor: "pointer",
            position: "absolute",
            bottom: matches ? -80 : -100,
            left: matches ? "35%" : "46%",
            rotate: "180deg",
            "&:hover": {
              background: "rgb(126,107,249)",
            },
          }}
        />
        <ArrowForwardIosIcon
          className="swiper-button-next"
          sx={{
            background: "transparent",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            marginLeft: "10px",
            border: "1px solid #fff",
            color: "#fff",
            cursor: "pointer",
            padding: "7px",
            position: "absolute",
            bottom: matches ? -80 : -100,
            left: matches ? "50%" : "50%",
            "&:hover": {
              background: "rgb(126,107,249)",
            },
          }}
        />
      </Box>
      <Swiper
        slidesPerView={matches ? 1 : 3}
        spaceBetween={7}
        // slidesPerGroup={1}
        loop={true}
        autoplay={{
          delay: 1500,
          // disableOnInteraction: false,
        }}
        // loopFillGroupWithBlank={true}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        modules={[Autoplay, Navigation]}
      >
        {children}
      </Swiper>
    </>
  );
}

export default StyledSwiper;
