import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import { StyledButton } from "../components/SmallComponents/AppComponents";

export default function BuyPage() {
  const matches = useMediaQuery("(max-width:950px)");

  return (
    <Box pb={10}>
      <Header />
      <Container maxWidth="xl">
        <Box
          pt={5}
          px={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography
            mb={4}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "40px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            <span
              style={{
                background: "transparent",
                backgroundImage:
                  "linear-gradient(273.16deg, #FF94FF 20.63%, #6DD8D4 75.15%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              RETIK Finance
            </span>{" "}
          </Typography>
          <StyledButton width="160px">Buy Now</StyledButton>
          <Typography
            mb={2}
            mt={8}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "40px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            How To Buy RETIK Finance (Retik)
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "122.2%",
              color: "gray",
              textAlign: "center",
            }}
          >
            Beginners guide on how to buy $RETIK tokens.
          </Typography>
          <Typography
            mb={2}
            mt={8}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "40px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            Buy On Exchanges
          </Typography>
          <StyledButton width="130px">LBank</StyledButton>
          <StyledButton width="160px" style={{ marginTop: "20px" }}>
            CoinStore
          </StyledButton>
          <Typography
            mb={2}
            mt={8}
            sx={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: matches ? "30px" : "40px",
              lineHeight: "122.2%",
              color: "#FFFFFF",
              textAlign: "center",
            }}
          >
            How To Buy RETIK Tokens On Uniswap
          </Typography>
        </Box>
        <Typography
          mt={2}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "20px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Welcome to this guide on how to buy RETIK tokens on Uniswap! Follow
          the steps below to get started:
        </Typography>

        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 1:
          </span>{" "}
          Install a Web3 Wallet
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Before you can buy RETIK tokens on Uniswap, you'll need a Web3 wallet
          like MetaMask. Install MetaMask by visiting the official MetaMask
          website and following the instructions.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 2:
          </span>{" "}
          Set up and Fund your Wallet
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Once you have installed MetaMask, create a new wallet or import an
          existing one. Make sure to securely store your wallet's seed phrase.
          Next, add some Ether (ETH) to your wallet by purchasing from a
          cryptocurrency exchange or transferring from another wallet.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 3:
          </span>{" "}
          Access Uniswap
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Visit the Uniswap website at https://app.uniswap.org to access the
          Uniswap decentralized exchange.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 4:
          </span>{" "}
          Connect MetaMask to Uniswap
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Click on the "Connect Wallet" button on the top right corner of the
          Uniswap interface. Follow the prompts to connect your MetaMask wallet
          to Uniswap.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 5:
          </span>{" "}
          Select Token
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          In the "From" field, click on the dropdown and search for "RETIK" in
          the token list. Once you see RETIK, select it.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 6:
          </span>{" "}
          Enter Amount
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          In the "Amount" field, enter the amount of RETIK tokens you want to
          buy. Make sure you have enough ETH in your wallet to cover the
          transaction fees and token purchase.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 7:
          </span>{" "}
          Swap Tokens
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Click on the "Swap" button to initiate the token swap. Review the
          details of the transaction and confirm it through MetaMask. Wait for
          the transaction to be processed on the Ethereum network.
        </Typography>
        <Typography
          mt={4}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Step 8:
          </span>{" "}
          Confirm Purchase
        </Typography>
        <Typography
          mt={1}
          pl={matches ? 2 : 4}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "17px",
            lineHeight: "122.2%",
            color: "gray",
            textAlign: "left",
          }}
        >
          Once the transaction is confirmed, you will receive your RETIK tokens
          in your MetaMask wallet. You can view your token balance by going to
          the "Assets" tab in MetaMask.
        </Typography>
      </Container>
    </Box>
  );
}
