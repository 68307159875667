import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import { Box, useMediaQuery } from "@mui/material";
import Gambling from "./pages/Gambling";
import Ecosystem from "./pages/Ecosystem";
import TierSystem from "./pages/TierSystem";
import Raffles from "./pages/Raffles";
import Utility from "./pages/Utility";
import Choose from "./pages/Choose";
import background from "./Images/background.png";
import Tokenomics from "./pages/Tokenomics";
import Contract from "./pages/Contract";
import Roadmap from "./pages/Roadmap";
import Faqs from "./pages/Faqs";
import Footer from "./pages/Footer";
import Team from "./pages/Team";
import homebg from "./Images/homebg.png";
import mbhomebg from "./Images/mbhomebg.svg";
import FeaturedIn from "./pages/FeaturedIn";
import { Route, Routes } from "react-router-dom";
import BuyPage from "./pages/BuyPage";
import ContactUs from "./pages/ContactUs";

function App() {
  const matches = useMediaQuery("(max-width:950px)");
  const matches1 = useMediaQuery("(min-width:1900px)");

  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  console.log(chain?.id, "chainid");
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Box position="relative" zIndex={1}>
                {/* <Box
                  sx={{
                    width: "0px",
                    height: "0px",
                    boxShadow: matches
                      ? ""
                      : "0px 250px 350px 340px rgb(109,216,212,0.3) ",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "0%",
                    left: "0%",
                  }}
                /> */}
                <img
                  src={matches ? mbhomebg : homebg}
                  width={matches1 ? "60%" : matches ? "240px" : "73.2%"}
                  style={{
                    right: "0%",
                    top: "0%",
                    position: "absolute",
                    zIndex: 0,
                  }}
                  alt=""
                />
                <Header />
                <HeroSection />
              </Box>
              <FeaturedIn />
              <Gambling />
              <Box
                sx={{
                  backgroundImage: `url(${background})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  // backgroundAttachment: "fixed",
                  // backgroundPosition: "100% 100%",
                  margin: 0,
                  padding: 0,
                }}
              >
                <Ecosystem />
                <TierSystem />
                {/* <Raffles /> */}
                <Choose />
                <Utility />

                {/* <Video /> */}
                <Tokenomics />
                <Contract />
                <Roadmap />
                <Team />
                <Faqs />
              </Box>
            </>
          }
        />
        <Route path="/buy" element={<BuyPage />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
