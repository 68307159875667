import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React, { useRef, useState } from "react";
import Header from "../components/Header";
import emailjs from "@emailjs/browser";
import { StyledButton } from "../components/SmallComponents/AppComponents";

export default function ContactUs() {
  const matches = useMediaQuery("(max-width:950px)");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); 
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_sdxv43s",
        "template_yiujna1",
        form.current,
        "SofyH48onPplOPhUD"
      )
      .then(
        (result) => {
          console.log(result.text);

          // document.getElementById("contact").reset();
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
          form.current.reset();
        }
      );
  };
  return (
    <>
      <Header />
      <Box pb={10}>
        <Typography
          mb={2}
          mt={3}
          sx={{
            fontFamily: "'Poppins'",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: matches ? "30px" : "40px",
            lineHeight: "122.2%",
            color: "#FFFFFF",
            textAlign: "center",
          }}
        >
          Contact Us
        </Typography>
        <Container maxWidth="sm">
          <Box
            mt={5}
            p={2}
            sx={{
              background:
                "linear-gradient(90deg, rgba(48,48,78,1) 0%, rgba(39,40,60,1) 50%)",
              borderRadius: "30px",
              width: matches ? "90%" : "100%",
              height: "100%",
            }}
          >
            <Box component="form" ref={form} onSubmit={sendEmail}>
              <Typography
                my={2}
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  textAlign: "center",
                  color: "gray",
                  fontFamily: "'Poppins'",
                }}
              >
                Leave your message and we will reply you soon.
              </Typography>
              <Typography
                mt={3}
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  textAlign: "left",
                  color: "#fff",
                  fontFamily: "'Poppins'",
                }}
              >
                Name*
              </Typography>
              <Box
                mt={1}
                sx={{
                  bgcolor: "transparent",
                  borderRadius: "10px",
                  border: "1px solid #fff",
                  width: matches ? "100%" : "100%",
                  height: "55px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: matches ? "50%" : "80%",
                    fontSize: "17px",
                    fontWeight: "500",
                    textAlign: "left",
                    color: "#fff",
                    fontFamily: "'Poppins'",
                    backgroundColor: "transparent",
                    paddingLeft: "15px",
                    border: "none",
                    outline: "none",
                  }}
                  // type="number"
                  placeholder="Enter Name"
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Typography
                mt={2}
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  textAlign: "left",
                  color: "#fff",
                  fontFamily: "'Poppins'",
                }}
              >
                Email*
              </Typography>
              <Box
                mt={1}
                sx={{
                  bgcolor: "transparent",
                  borderRadius: "10px",
                  border: "1px solid #fff",
                  width: matches ? "100%" : "100%",
                  height: "55px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: matches ? "50%" : "80%",
                    fontSize: "17px",
                    fontWeight: "500",
                    textAlign: "left",
                    color: "#fff",
                    fontFamily: "'Poppins'",
                    backgroundColor: "transparent",
                    paddingLeft: "15px",
                    border: "none",
                    outline: "none",
                  }}
                  // type="number"
                  placeholder="Enter your email"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Typography
                mt={2}
                mb={1}
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  textAlign: "left",
                  color: "#fff",
                  fontFamily: "'Poppins'",
                }}
              >
                Message*
              </Typography>
              <textarea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{
                  bgcolor: "transparent",
                  borderRadius: "10px",
                  border: "1px solid #fff",
                  width: matches ? "94%" : "96.5%",
                  height: "150px",
                  padding: "10px",
                  fontSize: "17px",
                  fontWeight: "400",
                }}
                placeholder="Your message here..."
              />
              <Box display="flex" justifyContent="center" mt={3}>
                <StyledButton type="submit" width="50%">
                  Submit
                </StyledButton>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
