import { useWeb3Modal } from "@web3modal/wagmi/react";
import { StyledButton } from "./AppComponents";
import { getAccount } from "@wagmi/core";

export const ExampleButton = () => {
  const { open } = useWeb3Modal();
  const { address } = getAccount();

  return (
    <StyledButton
      width={{ md: "210px", xs: "170px" }}
      style={{ height: "43px", fontWeight: "500" }}
      onClick={async () => {
        await open();
      }}
    >
      {address
        ? address.slice(0, 4) + "..." + address.slice(-4)
        : "Connect Wallet"}
    </StyledButton>
  );
};
